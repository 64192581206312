<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-container>
        <v-card elevation="10">
          <v-row>
            <v-col cols="12">
              <v-toolbar
                dark
                dense
                color="green"
              >
                <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                <v-toolbar-title>Oportunidades de venta.</v-toolbar-title>
                <v-spacer />
                <v-btn
                  align="right"
                  color="orange"
                  dark
                  small
                  class="ma-1"
                  @click="regresar"
                >
                  <v-icon>mdi-reply</v-icon>
                </v-btn>
                <v-btn
                  :loading="loading"
                  class="mx-2"
                  small
                  color="blue"
                  @click.stop="agregar"
                >
                  Procesar
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>

          <v-row
            align="start"
            class="ma-0"
          >
            <v-col
              cols="12"
              sm="6"
              md="6"
              xs="12"
            >
              <v-card
                elevation="10"
                class="pa-3"
              >
                <v-text-field
                  v-model="asunto"
                  prepend-icon="mdi-pencil"
                  
                  label="Título de la oportunidad."
                />

                <v-select
                  v-model="select"
                  prepend-icon="reorder"
                  :items="tipooport"
                  item-text="nomtipooport"
                  item-value="idtipooport"
                  label="Tipo de oportunidad"
                  persistent-hint
                  return-object
                  single-line
                  dense
                />
                <!-- <v-select
  	              class="ma-4"
  	              v-model="tipoopor"
  	              :items="tipos"
  	              label="Tipo de Oportunidad"
  	              prepend-icon="mdi-reorder-horizontal"
  	              clearable
  	              hide-details
  	            ></v-select> -->

                <v-text-field
                  v-model="conocioen"
                  dense
                  prepend-icon="mdi-newspaper"
                  label="Como nos conoció (Origen)"
                />

                <v-text-field
                  v-model="importe"
                  dense
                  prepend-icon-color="red"
                  prepend-icon="mdi-record-circle"
                  type="number"
                  label="Monto $ MXP"
                />


                <v-textarea
                  v-model="obs"
                  two-line
                  outlined
                  auto-grow
                  name="input-7-4"
                  label="Descripcion de la oportunidad"
                />

                Estatus:  <v-btn color="success">
                  Estatus {{ estatus }}
                </v-btn>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="6"
              xs="12"
            >
              <v-card
                class="pa-3"
                elevation="10"
              >
                <v-row justify="center">
                  <h3 class="mt-2">
                    Contacto
                  </h3>
                </v-row>

                <!-- CONTACTOS -->
                <leecontacto />

                <v-text-field
                  v-model="empresa"
                  prepend-icon="mdi-briefcase"
                  hide-details
                  class="ma-4"
                  label="Empresa"
                />

                <v-text-field
                  v-model="email"
                  dense
                  prepend-icon="mdi-at"
                  hide-details
                  class="ma-4"
                  label="Email"
                />
	        
                <v-text-field
                  v-model="telefono"
                  prepend-icon="mdi-phone"
                  hide-details
                  dense
                  class="ma-4"
                  label="Teléfono"
                />

                <v-text-field
                  v-model="celular"
                  prepend-icon="mdi-cellphone-iphone"
                  hide-details
                  dense
                  class="ma-4"
                  label="Celular"
                />
              
                <!-- LEE CLIENTE -->
                <leecliente />
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import leecontacto from '@/components/leecontacto'
import leecliente from '@/components/leecliente'
import horaSait from '@/mixins/horasait'

export default {
  components:{
    leecontacto,
    leecliente
  },
	  mixins: [horaSait],

  data(){
    return{
      loading: false,
      select: { nomtipoact: 'LLame', idtipoact: '1' },
      tipooport: [],
      tipos: ['Licencias', 'Nomina','Reservaciones','Sait Basico','Sait CI','Sait ERP','Soporte'],
      tipo: ['Licencias', 'vencidas'],
      value: null,
      seledura: '30',
      snackbar: false,
      time: this.hora(),
      time2:null,
      detalle:'',
      titulo:'',
      menu2: false,
		  menu3: false,
      fecha: new Date().toISOString().substr(0, 10),

	    menu1: false,
	    minutos:30,
	    asunto:'',
	    numcont:'',
      nomcont:'',
	    empresa:'',
	    telefono:'',
	    celular:'',
      email:'',
	    tipoopor:'',
	    obs:'',
	    numcli:'',
      conocioen: '',
	    temas:'',
	    numproy:'',
	    idcontacto: 0,
	    idcita:'',
	    nModo: 1,
      importe:0.00,
      estatus:1
    }
  },

  computed: {
    ...mapGetters('contactos', ['getContacto','getNewContacto']),
    ...mapGetters('clientes', ['getCliente']),
    ...mapGetters('login',['getdatosUsuario']),
   
    validaInfo(){
      return this.empresa !== '' &&
          this.email !== '' &&
          this.obs  !== ''  &&
          this.asunto  !== '' &&
          this.fecha !== ''
    },
  },
		
  watch: {
    getContacto(){
      this.actualizarDatoscontactos()
    },

  },

  created(){
    this.cargartipooport()
    console.log('router', this.$route.params.oportunidad )
    let ruta = this.$route.params.oportunidad
	  
    this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)

	    //MODO EDITAR
	    if ( ruta !== undefined ){
	        this.nModo=2
	        this.readonly= true
	        this.CargarInfo(this.$route.params.oportunidad)
	        this.modo = 'Editar'
	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
	    }
  },

 

  methods:{
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions('contactos',['setContacto','setLimpiarContacto']),
    ...mapActions('clientes',['setCliente','setLimpiarCliente']),

    cargartipooport(){
      // limpiar
      this.tipooport= []
      this.loading = true

      this.$http.get("v2/get.nomtipooport.activos" ).then(response=>{
        console.log("response", response)
        if (response.data.error === null){
          this.tipooport = response.data.result
          this.loading = false
          this.select  = response.data.result[0].nomtipooport
          // console.log("citas", this.actividades)
        }
          
      }).catch(error=>{
        	console.log(error)
        this.loading = false
      })
    },

    CargarInfo(oportunidad){

      console.log("oportunidad", oportunidad)
      this.idoportunidad = parseInt(oportunidad.idoportunidad)

      this.empresa		 = oportunidad.empresa
      this.email 			 = oportunidad.email
      this.telefono    = oportunidad.telefono
      this.celular     = oportunidad.celular
      
      this.asunto      = oportunidad.asunto
      this.conocioen   = oportunidad.conocioen
      this.obs         = oportunidad.obs
      this.creada      = oportunidad.creada
      this.estatus     = oportunidad.estatus
      this.importe     = oportunidad.importe
      this.estatus  = oportunidad.estatus

      this.idtipo    = oportunidad.idtipo
      this.idcontacto = oportunidad.idcontacto
      
      let contacto = {
        nomcont:    oportunidad.nomcont,
        idcontacto: oportunidad.idcontacto,
        empresa: oportunidad.empresa,
        telefono: oportunidad.telefono,
        email: oportunidad.email,
        celular: oportunidad.celular,
        numcli: "",
        sucursal: "",
        departamento: ""
      }

      this.setContacto( contacto)

      // this.leecliente  = oportunidad.leecliente
      // this.leecontacto = oportunidad.leecontacto
      

    },

    agregar(){
				
      //VALIDAR INFORMACION
	    if(!this.validaInfo){
	    	this.showSnack({
          text: "Debes registrar el asunto, los temas y la fecha",
          color: "orange",
          timeout: 3000,
        });
	      return
	    }
			
      // Determinar si el contacto es nuevo y ya existe.
      console.log("this.getContacto", this.getContacto)
      console.log("this.getContacto.idcontacto", this.getContacto.idcontacto)


    
      let payload = {
        contactos: {
          nomcont:    this.getContacto.nomcont,
          empresa:    this.empresa,
          telefono:   this.telefono,
          celular:    this.celular,
          email:      this.email,
          idweb:      this.getCliente.idweb
        },
        oportunidad: {
          idcontacto: this.idcontacto,
          asunto:     this.asunto,
          conocioen:  this.conocioen,
          obs:        this.obs,
          creada:     this.fecha,
          tipoopor:   this.tipoopor,
          estatus:    this.estatus,
          importe:    parseFloat(this.importe),
          idusuariosweb: this.idusuariosweb
        }
        // leecliente:  this.leecliente,
      }

      // En caso de de un contacto ya exista.
      // se debe mandar llamar a la api de actualizar contacto....

      if (this.getContacto.idcontacto != ''){
        payload.contactos.idcontacto = this.idcontacto
      }


      console.log("payload", payload)

      this.loading = true
	     //MODO NUEVO
      if (this.nModo == 1) {

        //cambiar estatus a 1. Creada.
        payload.oportunidad.estatus = 1
         
        this.$http.post('v2/insert.oportunidad', payload).then(response =>{
			        this.showSnack({
		            text: "Oportunidad agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

          this.loading= false
			        this.limpiarOportunidad()

	            setTimeout (() => this.$router.push({name:'catoportunidades'}),2000);
        }).catch(error=>{
          console.log(error)
         			this.showSnack({
		            text: "Problema para crear oportunidad."+ error,
		            color: "error",
		            timeout: 2000
		          });
          this.loading= false
        })
        
      }
      else {

        
        //cambiar estatus a 1. Creada.
        payload.oportunidad.idoportunidad = this.idoportunidad
                 
        // EDICION DE UNA Oportunidad
        this.$http.put('v2/update.oportunidad', payload).then(response =>{
          console.log(response)
          this.showSnack({
		            text: "Oportunidad actualizada correctamente.",
		            color: "primary",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'catoportunidades'}),2000);

        }).catch(error=>{
          console.log("error", error)
        	this.showSnack({
            text: "Problema para actualizar oportunidad.",
            color: "red",
            timeout: 2000,
          });
        
        })
      }
    },

    limpiarOportunidad() {

      this.leecontacto = ''
      this.empresa = ''
      this.telefono = ''
      this.leecliente = ''
      this.tipoopor= ''
      this.asunto= ''
      this.conocien =''
      this.obs = ''
				
      this.setLimpiarContacto()
      this.setLimpiarCliente()

    },
   				
 	  regresar(){
      console.log("ruta", this.$route.name)

      // MODO PANEL
      if (this.$route.name == 'panel') {
        if (this.idoportunidad == undefined){
          console.log("cerrar vista modal y limipiar. ")
          // this.$router.push({name:'panel'}).catch (err => {console.log(err)})
           
        } else {
          console.log("Catalogo regresar")
          this.$router.push({name:'catoportunidades'})
        }
      }

      // MODO CATALOGO
      if (this.$route.name == 'oportunidad' ){
        this.$router.push({name:'catoportunidades'})
      }
    },

    hora(){
      var f =new Date();
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if(hora < 10){
        hora = "0" + f.getHours()
      }
      if(minuto < 10){
        minuto = "0" + f.getMinutes()
      }
      if(segundo < 10){
        segundo = "0" + f.getSeconds()
      }
        
      return hora + ":" + minuto + ":" + segundo
    },
			
    actualizarDatoscontactos() {
      console.log("actualizarDatoscontactos contacto", this.getContacto)

      this.celular = this.getContacto.celular
      this.empresa = this.getContacto.empresa
      this.idcontacto = parseInt(this.getContacto.idcontacto)
      this.email  = this.getContacto.email
      this.numcli = this.getContacto.numcli
      this.numcont = this.getContacto.numcont
      this.nomcont = this.getContacto.nomcont
      
      this.sucursal = this.getContacto.sucursal
      this.telefono = this.getContacto.telefono
      this.departamento = this.getContacto.departamento

    },

  }
}
</script>